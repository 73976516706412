// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import TaskAllocator from "../../blocks/TaskAllocator/src/TaskAllocator";
import OrganisationHierarchy from "../../blocks/OrganisationHierarchy/src/OrganisationHierarchy";
import Documentation from "../../blocks/Documentation/src/Documentation";
import Polling from "../../blocks/Polling/src/Polling";
import ProjectTemplates from "../../blocks/ProjectTemplates/src/ProjectTemplates";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CfSuggestion2 from "../../blocks/CfSuggestion2/src/CfSuggestion2";
import Customform from "../../blocks/customform/src/Customform";
import TicketSystem from "../../blocks/TicketSystem/src/TicketSystem";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Notifications from "../../blocks/notifications/src/Notifications";
import Analytics from "../../blocks/analytics/src/Analytics";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import CfMeetings2 from "../../blocks/CfMeetings2/src/CfMeetings2";
import FormApprovalWorkflow from "../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import CfClickableWorldMap from "../../blocks/CfClickableWorldMap/src/CfClickableWorldMap";
import UserStatus from "../../blocks/userstatus/src/UserStatus";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import CfManagingManagementFees from "../../blocks/CfManagingManagementFees/src/CfManagingManagementFees";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import Reservations from "../../blocks/Reservations/src/Reservations";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Search from "../../blocks/search/src/Search";
import TaskList from "../../blocks/TaskList/src/TaskList";



const routeMap = {
TaskAllocator:{
 component:TaskAllocator,
path:"/TaskAllocator"},
OrganisationHierarchy:{
 component:OrganisationHierarchy,
path:"/OrganisationHierarchy"},
Documentation:{
 component:Documentation,
path:"/Documentation"},
Polling:{
 component:Polling,
path:"/Polling"},
ProjectTemplates:{
 component:ProjectTemplates,
path:"/ProjectTemplates"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
CfSuggestion2:{
 component:CfSuggestion2,
path:"/CfSuggestion2"},
Customform:{
 component:Customform,
path:"/Customform"},
TicketSystem:{
 component:TicketSystem,
path:"/TicketSystem"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
CfMeetings2:{
 component:CfMeetings2,
path:"/CfMeetings2"},
FormApprovalWorkflow:{
 component:FormApprovalWorkflow,
path:"/FormApprovalWorkflow"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
CfClickableWorldMap:{
 component:CfClickableWorldMap,
path:"/CfClickableWorldMap"},
UserStatus:{
 component:UserStatus,
path:"/UserStatus"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
CfManagingManagementFees:{
 component:CfManagingManagementFees,
path:"/CfManagingManagementFees"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
Reservations:{
 component:Reservations,
path:"/Reservations"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Search:{
 component:Search,
path:"/Search"},
TaskList:{
 component:TaskList,
path:"/TaskList"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;